import { RoleDropdown } from '@/types';
import {
  ApiOutlined,
  ClusterOutlined,
  EditOutlined,
  EyeOutlined,
  LineChartOutlined,
  NodeIndexOutlined,
} from '@ant-design/icons';
import { Button, Image, Select, Space, Tag, Typography } from 'antd';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrandTabItem } from '../styles';

import { ButtonLink } from '@/components/ButtonLink';

type Props = {
  brand: any;
  onUnlinkClick: () => void;
  onProfileChange: ({ newProfile, oldProfile }: any) => void;
  onStoreGroupClick: () => void;
};

const getBrandTag = (type: string) => {
  switch (type) {
    case 'enterprise':
      return (
        <Tag icon={<ClusterOutlined />} color="orange">
          Enterprise
        </Tag>
      );
    case 'growth':
      return (
        <Tag icon={<LineChartOutlined />} color="cyan">
          Growth
        </Tag>
      );
    case 'emerging':
      return (
        <Tag icon={<NodeIndexOutlined />} color="volcano">
          Emerging
        </Tag>
      );
    default:
      return <Tag>No Segment</Tag>;
  }
};

const getStatusTag = (status: boolean) => {
  switch (status) {
    case true:
      return <Tag color="red">Inactive</Tag>;
    default:
      return <Tag color="green">Active</Tag>;
  }
};

const getType = (isManaged: boolean) => {
  switch (isManaged) {
    case true:
      return <Tag color="red">Retailer</Tag>;
    default:
      return <Tag color="green">Brand</Tag>;
  }
};

const BrandItem = ({
  brand,
  onUnlinkClick,
  onProfileChange,
  onStoreGroupClick,
}: Props) => {
  const navigate = useNavigate();

  const scopeLabel = useMemo(() => {
    if (!brand.scope) {
      return 'Visibility All';
    }

    if (brand.scope?.type === 'all') {
      return 'Visibility All';
    }
    return `${brand.scope?.pks.length || 0} ${brand.scope?.type}(s)`;
  }, [brand.scope]);

  return (
    <BrandTabItem>
      <div className="content">
        <Typography.Title level={5} ellipsis>
          {brand.name}
        </Typography.Title>
      </div>
      <Image
        width={'100%'}
        height={116}
        src={brand.logoUrl}
        preview={false}
        fallback={'/assets/images/error_image.svg'}
      />
      <div className="content">
        <Space size={[0, 8]} wrap>
          {getStatusTag(brand.isInactive)}
          {getType(brand.isManaged)}
          {getBrandTag(brand.segment)}
        </Space>
        <div className="item">
          <Typography.Text>ID:</Typography.Text>
          <Typography.Text copyable>{brand.id}</Typography.Text>
        </div>
        <Select
          value={brand.profile}
          // disabled={brand.profile === 'Owner'}
          onChange={(value) =>
            onProfileChange({ newProfile: value, oldProfile: brand.profile })
          }
          placeholder="Select a role"
          options={RoleDropdown}
        />
        <Button
          // disabled={brand.profile === 'Admin' || brand.profile === 'Owner'}
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderWidth: '1px',
            paddingLeft: '12px',
            paddingRight: '12px',
          }}
          onClick={onStoreGroupClick}
        >
          {scopeLabel} <EditOutlined />
        </Button>
      </div>
      <div className="footer">
        <Button
          onClick={onUnlinkClick}
          disabled={brand.profile === 'Owner'}
          type="link"
          style={{ width: '100%', color: '#FF4D4F' }}
          icon={<ApiOutlined />}
        >
          Unlink
        </Button>
        <ButtonLink
          to={`/brand/${brand.id}/manage`}
          type="link"
          style={{ width: '100%', color: '#6F56BC' }}
          icon={<EyeOutlined />}
        >
          View
        </ButtonLink>
      </div>
    </BrandTabItem>
  );
};

export default BrandItem;
