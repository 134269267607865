import { BrandPlanPrice } from '@/pages/Brand/Plan/types';
import { handleError, httpGet, httpPut, httpPost } from './util/axios';
import queryString from 'qs';

export const getBrandDetails = async (id: string) => {
  try {
    return httpGet('brand/read', `admin/details?brandId=${id}`);
  } catch (error: any) {
    handleError(error);
  }
};

export const getFormData = async (id: string) => {
  try {
    return httpGet('brand/read', `admin/form-data?brandId=${id}`);
  } catch (error: any) {
    handleError(error);
  }
};

export const getBrandSubscriptions = async (brandId: string) => {
  return httpGet('brand/read', `admin/plan/subscriptions?brandId=${brandId}`);
};

export const getBrandPlansByType = async ({
  type,
  brandId,
  page,
  perPage,
  search,
  level,
}: {
  type: string;
  brandId: string;
  page: number;
  perPage: number;
  search: string;
  level: string;
}) => {
  const params = queryString.stringify({
    page,
    perPage,
    filters: {
      text: search,
      level,
      brandId,
    },
  });

  return httpGet('brand/read', `admin/plan/by-type/${type}?${params}`);
};

export const saveBrandWalletAjustment = async ({
  brandId,
  amount,
  operationType,
  annotations,
}: any) => {
  try {
    return httpPost('brand/write', 'admin/wallet/adjustment', {
      brandId,
      amount,
      operationType,
      annotations,
    });
  } catch (error: any) {
    handleError(error);
  }
};

export const getIndustries = async () => {
  try {
    return httpGet('brand/read', 'admin/industries');
  } catch (error: any) {
    handleError(error);
  }
};

export const updateBrand = async (brandId: string, args: any) => {
  try {
    return (await httpPut('brand/write', `admin/${brandId}/update`, args)) as
      | { status: true }
      | { status: false; message: string };
  } catch (error: any) {
    handleError(error);
  }
};

export const inactiveBrandUsers = async (brandId: string) => {
  try {
    return httpPut('brand/write', `admin/${brandId}/inactive-users`);
  } catch (error: any) {
    handleError(error);
  }
};

export const createBrand = async (args: any) => {
  try {
    return (await httpPost('brand/write', 'admin/create', args)) as
      | { status: true; brandId: string }
      | { status: false; message: string };
  } catch (error: any) {
    handleError(error);
  }
};

export const getBrandsByIds = async (ids: string[]) => {
  try {
    return (await httpGet(
      'brand/read',
      `admin/get-brands-by-ids?ids=${ids.join(',')}`,
    )) as
      | {
          status: true;
          brands: { id: string; name: string; logoUrl: string }[];
        }
      | { status: false; message: string };
  } catch (error: any) {
    handleError(error);
    return null;
  }
};

export const approveSignupBrandRequest = async (brandId: string) => {
  return httpPost('brand/write', 'admin/signup-request/approve', {
    brandId,
  }) as Promise<{ status: true } | { status: false; message?: string }>;
};

export const approveSignupBrandOnly = async (brandId: string) => {
  return httpPost('brand/write', 'admin/signup-request/approve-brand-only', {
    brandId,
  }) as Promise<{ status: true } | { status: false; message?: string }>;
};

export const rejectSignupBrandRequest = async ({
  brandId,
  rejectionReason,
  rejectionReasonDescription,
  rejectionItem,
}: {
  brandId: string;
  rejectionReason: string;
  rejectionReasonDescription: string;
  rejectionItem: string;
}) => {
  return httpPost('brand/write', 'admin/signup-request/reject', {
    brandId,
    rejectionReason,
    rejectionReasonDescription,
    rejectionItem,
  }) as Promise<{ status: true } | { status: false; message?: string }>;
};

export const rejectSignupBrandOnly = async ({
  brandId,
  rejectionReason,
  rejectionReasonDescription,
  rejectionItem,
}: {
  brandId: string;
  rejectionReason: string;
  rejectionReasonDescription: string;
  rejectionItem: string;
}) => {
  return httpPost('brand/write', 'admin/signup-request/reject-brand-only', {
    brandId,
    rejectionReason,
    rejectionReasonDescription,
    rejectionItem,
  }) as Promise<{ status: true } | { status: false; message?: string }>;
};

export const createBrandPlan = async (data: any) => {
  return httpPost('brand/write', `admin/plan/create`, data);
};

export const createBrandPlanPrice = async (
  planId: string,
  data: BrandPlanPrice,
): Promise<{
  status: boolean;
  prices: BrandPlanPrice[];
}> => {
  return httpPost('brand/write', `admin/plan/${planId}/price`, data);
};

export const updateBrandPlan = async (id: string, data: any) => {
  return httpPut('brand/write', `admin/plan/${id}/update`, data);
};

export const updatePlanActiveStatus = async (id: string, isActive: boolean) => {
  return httpPut('brand/write', `admin/plan/${id}/active`, {
    isActive,
  });
};

export const pauseBrandSubscription = async (id: string, brandId: string) => {
  return httpPut('brand/write', `admin/plan/${id}/pause`, {
    brandId,
  });
};

export const resumeBrandSubscription = async (id: string, brandId: string) => {
  return httpPut('brand/write', `admin/plan/${id}/resume`, {
    brandId,
  });
};

export const cancelBrandSubscription = async (id: string, brandId: string) => {
  return httpPut('brand/write', `admin/plan/${id}/cancel`, {
    brandId,
  });
};

export const subscribeBrandPlan = async ({
  planId,
  interval,
  brandId,
}: {
  planId: string;
  interval: string;
  brandId: string;
}) => {
  return httpPut('brand/write', `admin/plan/${planId}/subscribe`, {
    interval,
    brandId,
  });
};

export const getBrandStripeStatus = async (
  brandId: string,
): Promise<{
  consumer: boolean;
  paymentMethod: boolean;
  address: boolean;
}> => {
  return httpGet('brand/read', `admin/stripe-status?brandId=${brandId}`);
};
