import { Popconfirm, Select, Space, notification } from 'antd';
import { FC, PropsWithChildren, useState } from 'react';

import { RejectionContainer } from '@/pages/Brand/Manage/styles';
import {
  rejectSignupBrandOnly,
  rejectSignupBrandRequest,
} from '@/services/brand.service';
import type { Brand } from '@/types';

type Props = {
  brandId: string;
  brandCreatedFrom: Brand['createdFrom'];
  onReject?: () => void;
} & PropsWithChildren;

const itemOptions = [
  {
    label: 'Brand Logo',
    value: 'brand_logo',
  },
  {
    label: 'Brand Name',
    value: 'brand_name',
  },
];

const reasonOptions = [
  {
    label: 'The content violates platform guidelines',
    value: 'content_violates_guidelines',
  },
  {
    label: 'The image appears to be owned by another brand',
    value: 'image_owned_by_another_brand',
  },
  {
    label: "The field doesn't accurately represent the brand",
    value: 'field_doesnt_accurately_represent_brand',
  },
];

const BrandRejectionModal: FC<Props> = ({
  children,
  brandId,
  brandCreatedFrom,
  onReject,
}) => {
  const [rejectionItem, setRejectionItem] = useState<string | null>(null);
  const [rejectionReason, setRejectionReason] = useState<string | null>(null);
  const [contentKey, setContentKey] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const reset = () => {
    setRejectionItem(null);
    setRejectionReason(null);
    setContentKey((prev) => prev + 1);
  };

  return (
    <Popconfirm
      title="Brand Rejection Confirmation"
      open={isOpen}
      icon={false}
      description={
        <RejectionContainer key={`brand-rejection-modal-${contentKey}`}>
          <Space direction="vertical" size={16} style={{ width: '100%' }}>
            <Select
              style={{ width: '100%' }}
              placeholder="Select a rejection item"
              optionFilterProp="children"
              onChange={(value) => setRejectionItem(value)}
              options={itemOptions}
            />
            <Select
              style={{ width: '100%' }}
              placeholder="Select a rejection reason"
              optionFilterProp="children"
              onChange={(value) => setRejectionReason(value)}
              options={reasonOptions}
            />
          </Space>
        </RejectionContainer>
      }
      okText="Yes, I'm sure"
      cancelText="Cancel"
      onConfirm={async () => {
        if (!rejectionItem || !rejectionReason) return;

        const payload = {
          brandId,
          rejectionReason,
          rejectionReasonDescription:
            reasonOptions.find((item) => item.value === rejectionReason)
              ?.label ?? '',
          rejectionItem,
        };

        let status;
        if (brandCreatedFrom === 'self_signup_brand_only') {
          status = (await rejectSignupBrandOnly(payload)).status;
        } else {
          status = (await rejectSignupBrandRequest(payload)).status;
        }

        if (status) {
          notification.success({
            message: 'Request rejected successfully',
            placement: 'bottomRight',
            duration: 2,
          });

          setIsOpen(false);
          reset();
          onReject?.();
        } else {
          notification.error({
            message: 'Failed to reject request',
            placement: 'bottomRight',
            duration: 2,
          });
        }
      }}
      onCancel={() => {
        setIsOpen(false);

        reset();
      }}
      okButtonProps={{
        danger: true,
        disabled: !rejectionItem || !rejectionReason,
      }}
      cancelButtonProps={{ type: 'default' }}
    >
      <div onClick={() => setIsOpen(true)}>{children}</div>
    </Popconfirm>
  );
};

export default BrandRejectionModal;
