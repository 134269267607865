import { createContext, useContext, useState, useMemo } from 'react';
import { BrandPlanSubscription } from './types';

type Type = 'contest' | 'learning' | 'general' | 'consumer' | 'newsfeed';
export type BrandInterval = 'month' | 'year' | null;

type OpenModalProps = {
  type: Type;
  brandId: string;
  currentPriceId?: string;
};

type BrandPlanTabContextType = {
  isModalOpen: boolean;
  closeModal: () => void;
  type: Type;
  brandId: string;
  currentPriceId?: string;
  openModal: (props: OpenModalProps) => void;
  subscriptions: BrandPlanSubscription[];
  setSubscriptions: (subs: BrandPlanSubscription[]) => void;
  getMandatoryInterval: (type: Type) => BrandInterval;
};

const initialContext: BrandPlanTabContextType = {
  isModalOpen: false,
  closeModal: () => {},
  type: 'general',
  brandId: '',
  currentPriceId: undefined,
  openModal: () => {},
  subscriptions: [],
  setSubscriptions: () => {},
  getMandatoryInterval: () => null,
};

const BrandPlanTabContext =
  createContext<BrandPlanTabContextType>(initialContext);

export function BrandPlanTabProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState<Type>('general');
  const [brandId, setBrandId] = useState<string>('');
  const [subscriptions, setSubscriptions] = useState<BrandPlanSubscription[]>(
    [],
  );
  const [currentPriceId, setCurrentPriceId] = useState<string | undefined>(
    undefined,
  );

  const closeModal = () => setIsModalOpen(false);
  const openModal = (props: OpenModalProps) => {
    setIsModalOpen(true);
    setType(props.type);
    setBrandId(props.brandId);
    setCurrentPriceId(props.currentPriceId);
  };

  const getMandatoryInterval = (type: Type) => {
    if (subscriptions.length === 0) return null;

    const subscription = subscriptions.find((s) => s.planType !== type);

    if (!subscription) return null;

    return subscription.paymentInterval as BrandInterval;
  };

  const value = useMemo(
    () => ({
      isModalOpen,
      closeModal,
      type,
      brandId,
      currentPriceId,
      openModal,
      subscriptions,
      setSubscriptions,
      getMandatoryInterval,
    }),
    [isModalOpen, type, brandId, currentPriceId, subscriptions],
  );

  return (
    <BrandPlanTabContext.Provider value={value}>
      {children}
    </BrandPlanTabContext.Provider>
  );
}

export function useBrandPlanTab() {
  const context = useContext(BrandPlanTabContext);
  if (context === undefined) {
    throw new Error(
      'useBrandPlanTab must be used within a BrandPlanTabProvider',
    );
  }
  return context;
}

export default BrandPlanTabProvider;
