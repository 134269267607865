import styled from 'styled-components';

export const PlanTabContainer = styled.div`
  padding: 16px;

  .active-plans {
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    gap: 16px;

    .plan-item {
      background-color: #fff;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      height: 100%;

      .plan-item-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;

        .plan-item-header-title {
          font-size: 16px;
          font-weight: 600;
        }

        .plan-item-header-status {
          font-size: 12px;
          font-weight: 500;
          color: #000;
          border-radius: 16px;
          padding: 4px 8px;
          background-color: #f0f0f0;

          &.active {
            background-color: rgba(111, 86, 188, 0.1);
            color: #6f56bc;
          }

          &.expired {
            background-color: rgba(255, 152, 0, 0.1);
            color: #ff9800;
          }

          &.cancelled {
            background-color: rgba(244, 67, 54, 0.1);
            color: #f44336;
          }

          &.paused {
            background-color: rgba(3, 169, 244, 0.1);
            color: #03a9f4;
          }
        }
      }

      .plan-item-body {
        margin: 0 16px 16px 16px;

        .plan-item-limits {
          li {
            margin-bottom: 8px;
            font-size: 14px;
            color: #666;
          }
        }

        &.empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 250px;
          border: 1px dashed #e0e0e0;
          border-radius: 8px;
          flex: 1;
          padding: 24px 0;

          p {
            font-size: 14px;
            color: #666;
            margin-bottom: 16px;
          }

          .add-subscription-button {
            display: flex;
            align-items: center;
            gap: 8px;
            background-color: #f0f0f0;
            border-radius: 4px;
            padding: 8px 16px;
            font-size: 14px;
            font-weight: 600;
            color: #000;

            &:hover {
              background-color: #e0e0e0;
            }
          }
        }
      }

      .plan-item-actions {
        margin-top: auto;
        padding: 10px 16px;
        width: 100%;
        border-top: 1px solid #edf3f7;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;

        button {
          padding: 4px 12px;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #000;
          background-color: #fff;
          border: 1px solid #edf3f7;
          display: flex;
          align-items: center;
          gap: 4px;

          .anticon {
            font-size: 16px;
          }

          &.cancel {
            background-color: #ff4d4f;
            color: #fff;
          }

          &.pause,
          &.resume,
          &.change {
            border-color: #6f56bc;
            color: #6f56bc;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }

  .stripe-status {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #edf3f7;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 24px 0;

    > h3 {
      color: #ff4500;
      font-weight: 800;
    }

    .stripe-status-actions {
      font-size: 14px;
      color: #666;
      margin-bottom: 16px;
    }

    .stripe-bullet-points {
      h4 {
        font-size: 16px;
        font-weight: 600;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          font-size: 14px;
          color: #666;
          display: flex;
          align-items: center;
          gap: 8px;
          text-align: center;
        }
      }
    }
  }
`;

export const PlanTabModalContainer = styled.div`
  .plans-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 24px;

    > h3 {
      font-size: 16px;
      font-weight: 600;
    }

    .plans-header-actions {
      display: flex;
      align-items: center;
      gap: 8px;

      .ant-radio-group {
        width: 450px;
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;

      .plans-header-actions {
        width: 100%;
        .ant-input-group-wrapper {
          width: 400px;
        }
      }
    }
  }

  .plans-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        width: 100%;
        height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px dashed #edf3f7;
        border-radius: 8px;
      }
    }

    > div {
      background-color: #fff;
      border-radius: 8px;
      padding: 8px;
      border: 1px solid #edf3f7;

      > h2 {
        font-size: 16px;
        font-weight: 600;
      }

      .select {
        background-color: #6f56bc;
        color: #fff;
        padding: 2px 8px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
        margin-left: 8px;

        &:hover {
          background-color: #5a469a;
        }

        &:disabled {
          background-color: #f0f0f0;
          color: #666;
          cursor: not-allowed;
        }
      }

      .selected {
        color: #6f56bc;
        font-weight: 600;
        font-size: 12px;
        margin-left: 8px;
        background-color: rgba(111, 86, 188, 0.1);
        padding: 2px 8px;
        border-radius: 4px;
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export const SelectButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .info-icon {
    color: #949494;
    cursor: pointer;
    font-size: 18px;
  }
`;
