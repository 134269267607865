import {
  getBrandStripeStatus,
  getBrandSubscriptions,
  pauseBrandSubscription,
  resumeBrandSubscription,
} from '@/services/brand.service';
import { Brand } from '@/types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Descriptions, message, Popconfirm } from 'antd';
import { startCase } from 'lodash';
import { TabContainer } from '../WalletTab/styles';
import { PlanTabContainer } from './styles';
import { BrandPlanSubscription } from './types';
import { formatMoney } from '@/utils';
import {
  PauseCircleOutlined,
  EditOutlined,
  PlayCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { BrandPlanTabProvider, useBrandPlanTab } from './context';
import { BrandPlanTabModal } from './BrandPlanTabModal';

import { cancelBrandSubscription } from '@/services/brand.service';
import { FaCheckSquare, FaWindowClose } from 'react-icons/fa';
import { FaSquareXmark } from 'react-icons/fa6';
import { useEffect } from 'react';
import { useAudit } from '@/hooks';

type Params = {
  brand: Brand;
};

type PlanItemProps = {
  type: 'contest' | 'learning' | 'general' | 'consumer' | 'newsfeed';
  subscription: BrandPlanSubscription | undefined;
  brandId: string;
};

// Create a constant for plan type labels
const PLAN_TYPE_LABELS = {
  contest: 'Sales Incentive',
  learning: 'Digital Education',
  general: 'Retail Ops',
  consumer: 'Consumer Marketing',
  newsfeed: 'Newsfeed',
} as const;

// Extract limit formatting logic to a separate function
const formatLimit = (
  type: string,
  value: number,
  limit: number,
  currency: string,
): string => {
  const percentage = (value / limit) * 100;

  const formatters = {
    submissions: () => `${value} monthly submissions (${percentage}%)`,
    live_campaigns: () => `${value} live campaigns (${percentage}%)`,
    reach_stores: () => `${value} reach stores (${percentage}%)`,
    total_sales: () =>
      `${formatMoney(value, currency)} yearly sales (${percentage}%)`,
    newsfeed: () => `${value} newsfeed posts (${percentage}%)`,
    default: () => `${value}`,
  };

  return (formatters[type as keyof typeof formatters] || formatters.default)();
};

const PlanItem = ({ type, subscription, brandId }: PlanItemProps) => {
  const queryClient = useQueryClient();
  const { openModal } = useBrandPlanTab();

  const audit = useAudit({
    resourceName: 'brand-plan-tab',
  });

  const handleSubscriptionAction = async (
    action: 'pause' | 'resume' | 'cancel',
  ) => {
    if (!subscription) return;

    const actions = {
      pause: () => pauseBrandSubscription(subscription.planId, brandId),
      resume: () => resumeBrandSubscription(subscription.planId, brandId),
      cancel: () => cancelBrandSubscription(subscription.planId, brandId),
    };

    const { status } = await actions[action]();

    if (status) {
      queryClient.invalidateQueries({
        queryKey: ['brand-subscriptions', brandId],
      });
      message.success(`Subscription ${action}d successfully`);

      audit.onUpdate({
        target: 'brand-subscription',
        data: {
          brandId,
          planId: subscription.planId,
          action,
        },
      });
    } else {
      message.error(`Failed to ${action} subscription`);
    }
  };

  return (
    <div className={`plan-item ${type}`}>
      <div
        className="plan-item-header"
        style={{
          borderBottom: subscription ? '1px solid #edf3f7' : 'none',
          marginBottom: subscription ? '16px' : '0',
        }}
      >
        <span className="plan-item-header-title">{PLAN_TYPE_LABELS[type]}</span>

        {subscription?.hasPendingCancellation ? (
          <span className="plan-item-header-status cancelled">
            Cancellation Requested
          </span>
        ) : (
          subscription?.status && (
            <span className={`plan-item-header-status ${subscription.status}`}>
              {subscription.status}
            </span>
          )
        )}
      </div>

      {subscription ? (
        <>
          <div className="plan-item-body">
            <Descriptions layout="horizontal" column={1} size="small">
              <Descriptions.Item label="Plan Name">
                {subscription?.planName}
              </Descriptions.Item>

              <Descriptions.Item label="Plan Level">
                {startCase(subscription?.planLevel)}
              </Descriptions.Item>

              <Descriptions.Item label="Payment Interval">
                {subscription?.paymentInterval === 'year'
                  ? 'Yearly'
                  : 'Monthly'}
              </Descriptions.Item>

              <Descriptions.Item label="Payment Amount">
                {formatMoney(
                  subscription?.paymentAmount || 0,
                  subscription?.paymentCurrency || 'USD',
                )}{' '}
                ({subscription?.paymentCurrency})
              </Descriptions.Item>

              <Descriptions.Item label="Extra Per Submission Price">
                {formatMoney(
                  subscription?.extraPerSubmissionPrice || 0,
                  subscription?.paymentCurrency || 'USD',
                )}
              </Descriptions.Item>

              <Descriptions.Item label="Limits">
                <ul className="plan-item-limits">
                  {subscription.limits.map((limit) => (
                    <li key={limit.type}>
                      {formatLimit(
                        limit.type,
                        limit.limit,
                        limit.limit,
                        subscription.paymentCurrency || 'USD',
                      )}
                    </li>
                  ))}
                </ul>
              </Descriptions.Item>
            </Descriptions>
          </div>
          {!subscription?.hasPendingCancellation && (
            <div className="plan-item-actions">
              <button
                className="change"
                onClick={() =>
                  openModal({
                    type,
                    brandId,
                    currentPriceId: subscription.priceId,
                  })
                }
              >
                <EditOutlined />
                <span>Change</span>
              </button>
              <Popconfirm
                title={
                  subscription?.status === 'paused'
                    ? 'Are you sure you want to resume this subscription?'
                    : 'Are you sure you want to pause this subscription?'
                }
                onConfirm={() =>
                  handleSubscriptionAction(
                    subscription?.status === 'paused' ? 'resume' : 'pause',
                  )
                }
              >
                {subscription?.status === 'paused' ? (
                  <button className="resume">
                    <PlayCircleOutlined />
                    <span>Resume</span>
                  </button>
                ) : (
                  <button className="pause">
                    <PauseCircleOutlined />
                    <span>Pause</span>
                  </button>
                )}
              </Popconfirm>
              <Popconfirm
                title="Are you sure you want to cancel this subscription?"
                onConfirm={() => handleSubscriptionAction('cancel')}
              >
                <button className="cancel">Cancel</button>
              </Popconfirm>
            </div>
          )}
        </>
      ) : (
        <div className="plan-item-body empty">
          <p>No active subscription</p>
          <button
            className="add-subscription-button"
            onClick={() => openModal({ type, brandId })}
          >
            <PlusOutlined />
            Add Subscription
          </button>
        </div>
      )}
    </div>
  );
};

const PlanTab = ({ brand }: Params) => {
  const queryClient = useQueryClient();
  const { setSubscriptions } = useBrandPlanTab();

  const audit = useAudit({
    resourceName: 'brand-plan-tab',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const { data: subscriptions } = useQuery({
    queryKey: ['brand-subscriptions', brand.id],
    queryFn: async () => {
      const { subscriptions = [] } = await getBrandSubscriptions(brand.id);
      const result = subscriptions as BrandPlanSubscription[];
      setSubscriptions(result);
      return result;
    },
  });

  const { data: stripeStatus, isLoading: stripeStatusLoading } = useQuery({
    queryKey: ['brand-stripe-status', brand.id],
    queryFn: async () => getBrandStripeStatus(brand.id),
  });

  const stripeAvailable =
    stripeStatus?.consumer &&
    stripeStatus?.paymentMethod &&
    stripeStatus?.address;

  return (
    <TabContainer>
      <PlanTabContainer>
        {stripeAvailable && !stripeStatusLoading && (
          <div className="active-plans">
            <PlanItem
              type="contest"
              subscription={subscriptions?.find(
                (s) => s.planType === 'contest',
              )}
              brandId={brand.id}
            />

            <PlanItem
              type="learning"
              subscription={subscriptions?.find(
                (s) => s.planType === 'learning',
              )}
              brandId={brand.id}
            />

            <PlanItem
              type="general"
              subscription={subscriptions?.find(
                (s) => s.planType === 'general',
              )}
              brandId={brand.id}
            />

            <PlanItem
              type="consumer"
              subscription={subscriptions?.find(
                (s) => s.planType === 'consumer',
              )}
              brandId={brand.id}
            />

            <PlanItem
              type="newsfeed"
              subscription={subscriptions?.find(
                (s) => s.planType === 'newsfeed',
              )}
              brandId={brand.id}
            />
          </div>
        )}

        {!stripeAvailable && !stripeStatusLoading && (
          <div className="stripe-status">
            <h3>Brand Stripe settings are not complete</h3>

            <div className="stripe-status-actions">
              This brand don't have all required stripe settings to activate a
              subscription payment.
            </div>

            <div className="stripe-bullet-points">
              <h4>Stripe Settings</h4>

              <ul>
                <li>
                  {stripeStatus?.consumer ? (
                    <FaCheckSquare size={16} color="#00C49F" />
                  ) : (
                    <FaSquareXmark size={16} color="#FF4500" />
                  )}
                  <span>Customer</span>
                </li>

                <li>
                  {stripeStatus?.address ? (
                    <FaCheckSquare size={16} color="#00C49F" />
                  ) : (
                    <FaSquareXmark size={16} color="#FF4500" />
                  )}
                  <span>Consumer Address</span>
                </li>

                <li>
                  {stripeStatus?.paymentMethod ? (
                    <FaCheckSquare size={16} color="#00C49F" />
                  ) : (
                    <FaSquareXmark size={16} color="#FF4500" />
                  )}
                  <span>Payment Method</span>
                </li>
              </ul>
            </div>
          </div>
        )}
      </PlanTabContainer>
      <BrandPlanTabModal
        onChange={() => {
          queryClient.invalidateQueries({
            queryKey: ['brand-subscriptions', brand.id],
          });
        }}
      />
    </TabContainer>
  );
};

const PlanTabWrapper = ({ brand }: Params) => (
  <BrandPlanTabProvider>
    <PlanTab brand={brand} />
  </BrandPlanTabProvider>
);

export default PlanTabWrapper;
