import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useAudit } from '@/hooks';
import {
  changeUserRole,
  deleteUser,
  getMobileUserDetails,
  getPasswordToken,
  getPossibleUserRoles,
  getReferralLink,
} from '@/services/mobile-user.service';
import { useSession } from '@/store';
import { MobileUser } from '@/types';
import { formatISODate } from '@/utils';
import { UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Badge,
  Button,
  Descriptions,
  Modal,
  notification,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import { QRCodeCanvas } from 'qrcode.react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { ImEye, ImEyeBlocked } from 'react-icons/im';
import { Case, Switch } from 'react-if';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ChangeStoreModal } from '../ChangeStoreModal';
import DeviceTab from './DeviceTab';
import { TabsContainer, TabsControlContainer, UserPageHeader } from './styles';
import WalletTab from './WalletTab';

const Details = () => {
  const { id: userId } = useParams<{ id: string }>();
  const [userData, setUserData] = useState<MobileUser | null>(null);
  const [currentTab, setCurrentTab] = useState('tab-wallet');
  const session = useSession();
  const navigate = useNavigate();
  const [isResetTokenVisible, setVisibility] = useState(false);
  const [isHighRiskLevel, setIsHighRiskLevel] = useState(false);
  const [possibleUserRoles, setPossibleUserRoles] = useState<string[]>();
  const [isChangeStoreModalOpen, setChangeStoreModalOpen] = useState(false);
  const [popconfirmVisible, setPopconfirmVisible] = useState(false);

  const audit = useAudit({
    resourceName: 'mobile-user',
  });

  const userRoleOptions = useMemo(() => {
    if (!possibleUserRoles) return undefined;

    return possibleUserRoles.concat([''] /* No role */).map((role) => ({
      label: formatRoleName(role),
      value: role,
      disabled: role === '',
    }));
  }, [possibleUserRoles]);

  const initialUserRole = useMemo(() => {
    if (!userData || !possibleUserRoles) return undefined;

    if (possibleUserRoles.includes(userData.role)) return userData.role;
    return '';
  }, [possibleUserRoles, userData]);

  const hasUserEditPermission = useMemo(() => {
    return session.hasPermission('users.edit');
  }, [session]);

  useEffect(() => {
    audit.onAccess();
    loadUserDetails();
    loadPossibleUserRoles();
  }, []);

  const [code, setCode] = useState('******');

  const toggleCodeVisibility = () => {
    if (isResetTokenVisible) {
      setCode('******');
      setVisibility(!isResetTokenVisible);
      return;
    }

    setVisibility(!isResetTokenVisible);
    audit.onClick({
      target: `Revealed ResetTokenCode for user ${userData?.fullName}`,
      data: { userId },
    });
    loadResetToken();
  };

  const loadResetToken = async () => {
    if (!userId) return;

    const { resetPasswordToken } = await getPasswordToken(userId);
    if (resetPasswordToken === '') {
      setCode('Code expired/absent');
      return;
    }
    setCode(resetPasswordToken);
  };

  const loadUserDetails = async () => {
    if (!userId) return;

    const { user } = await getMobileUserDetails(userId);

    if (!user) {
      navigate('/mobile-user');
    }

    if (user.referralLink === '') {
      user.referralLink = await getReferralLink(userId);
    }
    setUserData(user);
    setIsHighRiskLevel(user?.riskLevel === 'high');
  };

  const loadPossibleUserRoles = async () => {
    const roles = await getPossibleUserRoles();
    if (!roles) return;

    setPossibleUserRoles(roles);
  };

  const qrRef = useRef<HTMLDivElement | null>(null);

  const handleDownload = () => {
    if (qrRef?.current) {
      const canvas = qrRef.current.querySelector('canvas');
      if (canvas) {
        const pngUrl = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');

        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'QRCode.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    }
  };

  const handleChangeRole = async (role: string) => {
    if (!hasUserEditPermission || !userId) return;

    audit.onClick({
      target: 'change-role',
      data: { userId, role },
    });

    const result = await changeUserRole({ userId, newUserRole: role });

    if (!result || result.status === false) {
      notification.error({
        message: 'Error',
        description:
          result?.message || 'Failed to update user role for unknown reasons',
      });
      return;
    }

    notification.success({
      message: 'Success',
      description: 'User role updated successfully',
    });
  };

  const onChangeStore = () => {
    // The audit is done in the modal
    setChangeStoreModalOpen(false);
    loadUserDetails();
  };

  const onDeleteUser = async (id: string | undefined) => {
    if (!id) return;

    const { status } = await deleteUser(id);

    if (status) {
      audit.onDelete({ target: 'delete', entityId: id });
      notification.success({
        message: 'User deleted successfully',
        placement: 'bottomRight',
        duration: 2,
      });
      setPopconfirmVisible(false);
      loadUserDetails();
      return;
    }
    notification.error({
      message: 'Failed to delete user',
      placement: 'bottomRight',
      duration: 2,
    });
  };

  const onCancelDelete = () => {
    setPopconfirmVisible(false);
  };

  return (
    <Layout>
      <Modal
        title="Delete User"
        open={popconfirmVisible}
        onOk={() => onDeleteUser(userId)}
        onCancel={() => onCancelDelete()}
        okText="Yes, I'm sure"
        cancelText="Cancel"
      >
        Are you sure to delete this user?
      </Modal>
      <PageHeader title="Mobile User Details">
        <Space>
          <Button
            disabled={!hasUserEditPermission || userData?.isDeleted}
            danger
            onClick={() => setPopconfirmVisible(true)}
          >
            Delete user
          </Button>

          <Button type="primary" onClick={() => setChangeStoreModalOpen(true)}>
            Change store
          </Button>
          <ChangeStoreModal
            open={isChangeStoreModalOpen}
            userId={userId!}
            storeId={userData?.storeId || userData?.store?.id}
            onChangeStore={onChangeStore}
            onCancel={() => setChangeStoreModalOpen(false)}
          />
        </Space>
      </PageHeader>

      <UserPageHeader>
        <div className="main-info">
          <div className="avatar">
            <Badge.Ribbon
              color={
                userData?.isDeleted
                  ? 'red'
                  : userData?.isActive
                  ? 'green'
                  : 'gray'
              }
              text={
                userData?.isDeleted
                  ? 'Deleted User'
                  : userData?.isActive
                  ? 'Active User'
                  : 'Inactive User'
              }
            >
              <Avatar
                size={166}
                src={!userData?.isDeleted ? userData?.avatarUrl || null : null}
                shape="square"
                icon={<UserOutlined />}
              />
            </Badge.Ribbon>
          </div>
          <div className="info">
            <div className="name-container">
              <Typography.Title level={2}>
                {userData?.fullName}
              </Typography.Title>
              <Descriptions>
                <Descriptions.Item label="ID">
                  <Typography.Text copyable>{userData?.id}</Typography.Text>
                </Descriptions.Item>
              </Descriptions>
            </div>
            <div className="details">
              <Descriptions layout="vertical">
                <Descriptions.Item label="Email">
                  <Typography.Text copyable editable={false}>
                    {userData?.email}
                  </Typography.Text>
                </Descriptions.Item>
                {userData?.phoneNumber && (
                  <Descriptions.Item label="Phone">
                    <Typography.Text copyable editable={false}>
                      {userData?.phoneNumber}
                    </Typography.Text>
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="Store">
                  {userData?.store?.id ? (
                    <Link to={`/store/${userData?.store?.id}/manage`}>
                      {userData?.store?.name} <FaExternalLinkAlt />
                    </Link>
                  ) : (
                    <Typography.Text type="secondary">No store</Typography.Text>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Referral Code">
                  <Typography.Text
                    copyable={{
                      tooltips: ['Copy referral code', 'Copied!'],
                      text: userData?.referralCode,
                    }}
                  >
                    <code>{userData?.referralCode}</code>
                  </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label="Account Confirmation">
                  {userData?.isConfirmed ? (
                    <Badge status="success" text="Done" />
                  ) : (
                    <Badge status="default" text="Pending" />
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Registration Date">
                  <Typography.Text>
                    {formatISODate(userData?.createdAt || '')}
                  </Typography.Text>
                </Descriptions.Item>
                {userData?.deletedAt && (
                  <Descriptions.Item label="Deletion Date">
                    <Typography.Text>
                      {formatISODate(userData?.deletedAt || '')}
                    </Typography.Text>
                  </Descriptions.Item>
                )}
                {userData?.lastActivityAt && (
                  <Descriptions.Item label="Last Activity">
                    <Typography.Text>
                      {formatISODate(userData?.lastActivityAt)}
                    </Typography.Text>
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="Risk Level">
                  <Switch>
                    <Case condition={userData?.riskLevel === 'low'}>
                      <Tag color="success">Low</Tag>
                    </Case>
                    <Case condition={userData?.riskLevel === 'medium'}>
                      <Tag color="orange">Medium</Tag>
                    </Case>
                    <Case condition={userData?.riskLevel === 'high'}>
                      <Tag color="red">High</Tag>
                    </Case>
                  </Switch>
                </Descriptions.Item>
                <Descriptions.Item label="Role">
                  {!userRoleOptions || initialUserRole === undefined ? (
                    <Spin tip="Loading..." />
                  ) : !hasUserEditPermission ? (
                    <span>{formatRoleName(initialUserRole)}</span>
                  ) : (
                    <Select
                      style={{ minWidth: 200 }}
                      options={userRoleOptions}
                      defaultValue={initialUserRole}
                      onChange={handleChangeRole}
                    />
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Reset Password Code">
                  <Button
                    onClick={toggleCodeVisibility}
                    size="small"
                    shape="round"
                  >
                    {isResetTokenVisible ? <ImEyeBlocked /> : <ImEye />}
                  </Button>
                  &emsp;
                  {code ? <span>{code}</span> : <span>{'*'.repeat(6)}</span>}
                </Descriptions.Item>
                <Descriptions.Item label="Referral Link">
                  <Typography.Text
                    copyable={{
                      tooltips: ['Copy Referral Link', 'Copied!'],
                      text: userData?.referralLink,
                    }}
                  >
                    {userData?.referralLink}
                  </Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item>
                  {userData?.referralLink && (
                    <>
                      <a onClick={handleDownload}>
                        <div ref={qrRef} style={{ marginLeft: '50px' }}>
                          <QRCodeCanvas value={userData?.referralLink} />
                        </div>
                      </a>
                    </>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        </div>
      </UserPageHeader>

      <TabsControlContainer>
        <Tabs
          items={[
            {
              key: 'tab-wallet',
              label: 'Wallet',
            },
            {
              key: 'tab-logs',
              label: 'Logs',
              disabled: true,
            },
            {
              key: 'tab-device',
              label: 'Device',
            },
          ]}
          onChange={(tab: string) => setCurrentTab(tab)}
        />
      </TabsControlContainer>

      <TabsContainer>
        <Switch>
          <Case condition={currentTab === 'tab-wallet'}>
            {userId && (
              <WalletTab
                userId={userId}
                earnings={userData?.earnings || 0}
                pendingEarnings={userData?.pendingEarnings || 0}
                isHighRiskLevel={isHighRiskLevel || false}
                onRefresh={loadUserDetails}
              />
            )}
          </Case>
          <Case condition={currentTab === 'tab-device'}>
            {userId && (
              <DeviceTab
                userId={userId}
                isHighRiskLevel={isHighRiskLevel}
                onRefresh={loadUserDetails}
              />
            )}
          </Case>
        </Switch>
      </TabsContainer>
    </Layout>
  );
};

function formatRoleName(role: string) {
  if (role === '') return 'None';

  return role
    .split('_')
    .map((word) => word[0].toLocaleUpperCase() + word.slice(1))
    .join(' ');
}

export default Details;
