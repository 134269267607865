import { getBrandPlansByType } from '@/services/brand.service';
import { formatMoney } from '@/utils';
import { CheckOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Descriptions,
  Divider,
  Empty,
  Input,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Popover,
  Radio,
} from 'antd';
import { startCase } from 'lodash';
import { BrandInterval, useBrandPlanTab } from './context';
import { PlanTabModalContainer, SelectButtonWrapper } from './styles';
import { BrandPlan } from './types';

import { subscribeBrandPlan } from '@/services/brand.service';
import { useMemo, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { useDebounce } from 'react-use';
import { useAudit } from '@/hooks';

type ModalProps = {
  onChange: () => void;
};

type IntervalWarningProps = {
  interval: BrandInterval;
};

type QueryResult = {
  items: BrandPlan[];
  _meta: {
    totalCount: number;
    pageCount: number;
    currentPage: number;
    perPage: number;
  };
};

const monthPopoverMessage =
  "This brand has a monthly subscription active. That's why annual plans are not available.";
const yearlyPopoverMessage =
  "This brand has a yearly subscription active. That's why monthly plans are not available.";

const IntervalWarning = ({ interval }: IntervalWarningProps) => {
  const title =
    interval === 'month'
      ? 'Only Monthly Subscription'
      : 'Only Yearly Subscription';
  const content =
    interval === 'month' ? monthPopoverMessage : yearlyPopoverMessage;

  return (
    <Popover
      title={title}
      content={
        <div style={{ maxWidth: 300, fontSize: 12, color: '#949494' }}>
          {content}
        </div>
      }
    >
      <FaInfoCircle className="info-icon" />
    </Popover>
  );
};

const PlanLimits = ({ plan }: { plan: BrandPlan }) => (
  <>
    <Divider orientation="left" orientationMargin="0">
      Limits
    </Divider>
    <ul className="plan-item-limits">
      {Object.entries({
        'Live Campaigns': plan.limitLiveCampaigns?.total,
        'Missions per Campaign': plan.limitMissionsPerCampaigns?.total,
        'Total Sales': plan.limitTotalSales?.total
          ? formatMoney(plan.limitTotalSales.total, plan.currency)
          : null,
        'Reach Stores': plan.limitReachStores?.total,
        Submissions: plan.limitSubmissions?.total,
        Newsfeed: plan.limitNewsfeed?.total,
      }).map(
        ([label, value]) =>
          value !== undefined && (
            <li key={label}>
              <strong>{label}: </strong>
              <span>{value || 'Unlimited'}</span>
            </li>
          ),
      )}
    </ul>
  </>
);

const PlanPricing = ({
  plan,
  currentPriceId,
  onSubscribe,
  mandatoryInterval,
}: {
  plan: BrandPlan;
  currentPriceId: string;
  onSubscribe: (interval: 'month' | 'year') => Promise<void>;
  mandatoryInterval?: BrandInterval;
}) => (
  <>
    <Divider orientation="left" orientationMargin="0">
      Prices
    </Divider>
    <Descriptions column={1} size="small">
      {[
        {
          label: 'Monthly',
          price: plan.monthlyPrice,
          priceId: plan.monthlyPriceId,
          interval: 'month' as const,
        },
        {
          label: 'Yearly',
          price: plan.yearlyPrice,
          priceId: plan.yearlyPriceId,
          interval: 'year' as const,
        },
      ].map(({ label, price, priceId, interval }) => (
        <Descriptions.Item key={label} label={label}>
          {price > 0 ? (
            <>
              {formatMoney(price, plan.currency)}
              {currentPriceId === priceId ? (
                <span className="selected">Selected</span>
              ) : (
                <SelectButtonWrapper>
                  <Popconfirm
                    title="Are you sure you want to select this plan?"
                    okText="Yes, I'm sure"
                    cancelText="Cancel"
                    disabled={
                      mandatoryInterval === 'month' && interval === 'year'
                    }
                    onConfirm={() => onSubscribe(interval)}
                  >
                    <Button
                      className="select"
                      size="small"
                      icon={<CheckOutlined />}
                      disabled={
                        mandatoryInterval === 'month' && interval === 'year'
                      }
                    >
                      Select
                    </Button>
                  </Popconfirm>
                  {mandatoryInterval === 'month' && interval === 'year' && (
                    <IntervalWarning interval={'month'} />
                  )}
                </SelectButtonWrapper>
              )}
            </>
          ) : (
            <span style={{ color: '#9b9b9b' }}>Not available</span>
          )}
        </Descriptions.Item>
      ))}
    </Descriptions>
  </>
);

export const BrandPlanTabModal = ({ onChange }: ModalProps) => {
  const {
    isModalOpen,
    closeModal,
    type,
    brandId,
    currentPriceId,
    getMandatoryInterval,
  } = useBrandPlanTab();

  const [search, setSearch] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [level, setLevel] = useState('');
  const [page, setPage] = useState(1);
  const [perPage] = useState(6);

  const audit = useAudit({
    resourceName: 'brand-plan-tab',
  });

  useDebounce(
    () => {
      setSearch(searchInput);
    },
    300,
    [searchInput],
  );

  const plansQuery = useQuery<QueryResult>({
    queryKey: ['plans', type, brandId, page, perPage, search, level],
    queryFn: () =>
      getBrandPlansByType({ type, brandId, page, perPage, search, level }),
    enabled: isModalOpen && !!type && !!brandId,
  });

  const mandatoryInterval = useMemo(
    () => getMandatoryInterval(type),
    [getMandatoryInterval, type],
  );

  const totalPages = plansQuery.data?._meta?.pageCount || 1;

  const handleSubscribe = async (
    plan: BrandPlan,
    interval: 'month' | 'year',
  ) => {
    const { status } = await subscribeBrandPlan({
      planId: plan.id,
      interval,
      brandId,
    });

    if (status) {
      closeModal();
      onChange();
      message.success('Plan selected successfully');

      audit.onUpdate({
        target: 'brand-subscription',
        data: {
          brandId,
          planId: plan.id,
          action: 'subscribe',
          interval,
        },
      });
    } else {
      message.error('Failed to select plan');
    }
  };

  return (
    <Modal
      open={isModalOpen}
      title={
        <h2 className="font-size: 24px; font-weight: 900;">
          <span>
            {currentPriceId ? 'Change Subscription' : 'Add Subscription'}
          </span>
          <strong style={{ marginLeft: 8, color: '#6f56bc' }}>
            ({type === 'learning' && 'Digital Education'}
            {type === 'contest' && 'Sales Incentive'}
            {type === 'general' && 'Retail Ops'}
            {type === 'consumer' && 'Consumer Marketing'}
            {type === 'newsfeed' && 'Newsfeed'})
          </strong>
        </h2>
      }
      onCancel={closeModal}
      footer={
        totalPages > 1 && (
          <Pagination
            current={page}
            pageSize={perPage}
            total={totalPages}
            onChange={(page) => setPage(page)}
          />
        )
      }
      width={1200}
      loading={plansQuery.isLoading}
    >
      <PlanTabModalContainer>
        <div className="plans-header">
          <h3>Available Plans</h3>
          <div className="plans-header-actions">
            <Input.Search
              placeholder="Search"
              value={searchInput}
              allowClear
              onChange={(e) => setSearchInput(e.target.value)}
              onSearch={(value) => setSearch(value)}
            />
            <Radio.Group
              defaultValue={level}
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              buttonStyle="solid"
            >
              <Radio.Button value="">All</Radio.Button>
              <Radio.Button value="basic">Basic</Radio.Button>
              <Radio.Button value="pro">Pro</Radio.Button>
              <Radio.Button value="enterprise">Enterprise</Radio.Button>
            </Radio.Group>
          </div>
        </div>
        <div
          className={`plans-container ${
            plansQuery.data?.items.length === 0 ? 'empty' : ''
          }`}
        >
          {plansQuery.data?.items.map((plan) => (
            <div key={`plan-${plan.id}`}>
              <h2>{plan.name.en}</h2>

              <Descriptions column={1} size="small">
                <Descriptions.Item label="Level">
                  {startCase(plan.level)}
                </Descriptions.Item>
                <Descriptions.Item label="Currency">
                  {plan.currency}
                </Descriptions.Item>
              </Descriptions>

              <PlanPricing
                plan={plan}
                currentPriceId={currentPriceId || ''}
                onSubscribe={(interval) => handleSubscribe(plan, interval)}
                mandatoryInterval={mandatoryInterval}
              />

              <PlanLimits plan={plan} />
            </div>
          ))}

          {plansQuery.data?.items.length === 0 && (
            <Empty description="No plans found" />
          )}
        </div>
      </PlanTabModalContainer>
    </Modal>
  );
};
